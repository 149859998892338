import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { AppContainer } from '@rr/ui';

import { Layout } from 'components';
export function App() {
  return (
    <AppContainer>
      <Router>
        <Switch>
          <Route>
            <Layout>
              <Switch>
                <Redirect exact from="/" to="/lessons" />
                <Route path="/lessons">Lessons</Route>
                <Route path="/settings">Settings</Route>
              </Switch>
            </Layout>
          </Route>
        </Switch>
      </Router>
    </AppContainer>
  );
}

export default App;
