import { css } from '@emotion/react';
import { Header, Logo, Navigation, NavigationLink } from '@rr/ui';
import { useAuth } from '@rr/auth-context';

// TODO: Replace with Route constants
const NAVIGATION: NavigationLink[] = [
  {
    to: '/lessons',
    label: 'Lessons',
    exact: true,
  },
  {
    to: '/settings',
    label: 'Settings',
    exact: true,
  },
];

const Layout: React.FC = ({ children }) => {
  const styles = css`
    background: #ccc;
    height: 100vh;

    .content {
      flex: 1 0 auto;
      height: calc(100vh - 64px);
    }

    .Logout {
      margin-left: auto;
      appearance: none;
      font: inherit;
      background: none;
      background: rgba(255, 255, 255, 0.1);
      padding: 12px 24px 11px;
      line-height: 1;
      color: inherit;
      border-radius: 4px;
      border: none;

      &:hover {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  `;

  const { logout } = useAuth();

  return (
    <main css={styles}>
      <Header>
        <Logo />
        <Navigation items={NAVIGATION} />
        <button className="Logout" onClick={logout}>
          Log out
        </button>
      </Header>
      <div className="content">{children}</div>
    </main>
  );
};

export { Layout };
